import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner,
  Tooltip,
} from "reactstrap";

function DataCard({ isLoading, cardImage, cardData, message, toolTipData }) {
  const [tooltip, setTooltip] = useState(false);

  return (
    <Card className="card-stats w-100" style={{ height: "100%" }}>
      <CardBody className="">
        <Row>
          <div className="col">
            <CardTitle tag="h3" className="text-color-mw my-0">
              {cardData.title}
              <FaInfoCircle
                id={toolTipData.id}
                fontSize={"18px"}
                className="ml-2"
              />
              <Tooltip
                placement={"top"}
                isOpen={tooltip}
                target={toolTipData.id}
                toggle={() => {
                  setTooltip((st) => !st);
                }}
              >
                {toolTipData.message}
              </Tooltip>
            </CardTitle>
            {message === "No data found" ? (
              <span className="h4 text-muted font-weight-bold my-1">
                No Data Found
              </span>
            ) : isLoading ? (
              <Spinner size="sm">Loading...</Spinner>
            ) : (
              <span className="h3 text-white font-weight-bold my-1">
                {cardData.count}
              </span>
            )}
          </div>
          <Col className="col-auto">
            <div
              className="icon icon-shape text-white rounded-circle shadow"
              style={{
                backgroundColor: "#731e2f",
                width: "70px",
                height: "70px",
              }}
            >
              <img src={cardImage} width={40} alt="" />
            </div>
          </Col>
        </Row>
        {!isLoading &&
          message !== "No data found" &&
          cardData.durationDays !== 0 && (
            <p className="mb-0 text-muted text-sm">
              <span
                className={
                  cardData.sinceValue.includes("decrease")
                    ? "text-warning mr-2"
                    : "text-success mr-2"
                }
              >
                <i
                  className={
                    cardData.sinceValue.includes("decrease")
                      ? "fas fa-arrow-down"
                      : "fa fa-arrow-up"
                  }
                />{" "}
                {cardData.sinceValue}
              </span>{" "}
              <span className="text-nowrap">
                than previous {cardData.durationDays} days
              </span>
            </p>
          )}
      </CardBody>
    </Card>
  );
}

export default DataCard;
